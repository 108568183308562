import React, { useContext } from "react";
import styled from "styled-components";
import LanguageContext from "../../Context/languajeContext";
import getDate from "../../scripts/getDate";
import linkIcon from "./link-external-small-ltr-progressive.svg";

const JobsList = ({ data }) => {
	const { language } = useContext(LanguageContext);
	const componentData = {
		aptitudes: {
			english: "Aptitudes",
			spanish: "Aptitudes",
		},
	};
	const generateTimeFromDate = (date) => {
		new Date(...date.split("-")).getTime();
	};
	return (
		<ItemsContainer>
			{data
				.sort(
					(a, b) => generateTimeFromDate(b.date) - generateTimeFromDate(a.date)
				)
				.map(({ aptitudes, companyTitle, date, link }) => (
					<Item>
						<Title>
							{companyTitle}
							<a href={link} target="_blank" rel="noreferrer">
								<FreelancingLinkIcon src={linkIcon} alt="" />
							</a>
						</Title>
						<DateContainer>{getDate(date, language)}</DateContainer>
						<Aptitudes>
							<span>{componentData.aptitudes[language]}: </span>
							<AptitudesList>
								{aptitudes.map((aptitud) => (
									<li>{aptitud}</li>
								))}
							</AptitudesList>
						</Aptitudes>
					</Item>
				))}
		</ItemsContainer>
	);
};

const FreelancingLinkIcon = styled.img`
	&:hover {
		border-bottom: 1px solid #5584e4;
		cursor: pointer;
	}
`;

const Item = styled.div``;

const ItemsContainer = styled.div`
	width: 100% !important;
	display: flex;
	flex-direction: column;
	row-gap: 15px;
`;

const DateContainer = styled.p`
	width: 100%;
	column-gap: 3px;
`;

const Title = styled.p`
	color: #fff;
	display: flex;
	column-gap: 3px;
`;

const Aptitudes = styled.div`
	display: flex;
	font-size: 0.95rem;
	& > span {
		font-weight: 600;
	}
`;

const AptitudesList = styled.ul`
	display: flex;
	column-gap: 4px;
	margin-left: 4px;
`;

export default JobsList;
