import { Ring } from '@uiball/loaders'
import styled from 'styled-components'

function Loading({color, size}) {
    const Styles = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `
    return (
        <Styles className="loading">
            <Ring size={size ? size : 60} color={color ? color : "#fff" }/>
        </Styles>
    )
}

export default Loading