import React, {useContext, useState} from 'react'
import styled from "styled-components";
import axios from "axios";

import languageContext from '../../Context/languajeContext'
import Loading from "../../components/Loading/Loading";

const Blog = () => {
  
  const language = useContext(languageContext).language

  const data = {
    english: {
      soon: "Soon...",
      readingTime: "Reading Time"
    },
    spanish: {
      soon: "Pronto",
      readingTime: "Tiempo de lectura"
    }
  }

  const [loading, setLoading] = useState(true)
  const [blogPosts, setBlogPosts] = useState({})

  const Styles = styled.div`
    width: 66%;

    margin: auto;

    color: #fff;

    .post {

      margin-bottom: 2vh;
      
      border: 5px solid #acacac;

      border-radius: 15px;

        .data {

          padding: 2vh 2vw 0px 2vw;
        
        
        .title, .short-post {
          margin-bottom: 1.5vh;

          word-wrap: break-word;
        }

        .reading-time {
          .time {
            color: #f39a35;
          }
        }
      }

      .link-button {
        width: 100%;

        padding: 1.5vh 0px;
        margin-top: 1.5vh;

        background-color: #ffffff39;
        border: none;
      }
    }

    @media (max-width: 930px) {
			width: 95vw;
    }
  `

  React.useEffect(() => {
    async function getBlogPosts() {
      try {
        const response = await axios({
					method: "post",
					url: "https://open-react-blog-api.up.railway.app/user",
					data: {
						user: "francisco-pessano",
					},
				});

        setBlogPosts(response.data.posts)
      } catch (err) {
        console.log(err);    
      }
      
      setLoading(false)
    }

    getBlogPosts()
  }, [])

  const formatPostText = (markdownText) => {
		// Remove text from titles
		const strippedMarkdown = markdownText.replace(/(^#+\s+)|(\n#+\s+)/g, "");

		// Strip HTML tags
		const htmlTagRegex = /<(?:.|\n)*?>/gm;
		const plainText = strippedMarkdown.replace(htmlTagRegex, "");

		// Remove bold text
		const boldRegex = /\*\*(.*?)\*\*/g;
		const plainTextNoBold = plainText.replace(boldRegex, "$1");

		// Remove image links
		const imageRegex = /!\[.*?\]\((.*?)\)/g;
		const plainTextNoImages = plainTextNoBold.replace(imageRegex, "");

		return `${plainTextNoImages.substring(0, 200)}...`;
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<Styles>
					{blogPosts.map((blogPost) => (
						<div className="post">
							<div className="data">
								<h3 className="title">{blogPost.title}</h3>
								<p className="short-post">{formatPostText(blogPost.post)}</p>
								<p className="reading-time">
									{data[language].readingTime}:{" "}
									<span className="time">{blogPost.readingTime}</span>
								</p>
							</div>
							<a
								href={`https://open-react-blog.netlify.com/francisco-pessano/post/${blogPost.id}`}
								target="_blank"
								rel="noreferrer"
							>
								<button className="link-button link">Go to post ↗</button>
							</a>
						</div>
					))}
				</Styles>
			)}
		</>
	);
}

export default Blog