import { DateTime } from "luxon";
import React from "react";
import styled from "styled-components";
import ImagesPresentation from "../ImagesPresentation/ImagesPresentation";
import defaultPlaceholder from "./images/default-placeholder.png";

const ProjectPill = ({ data, language }) => {
	const componentData = {
		localeDateString: {
			english: "en-US",
			spanish: "es-AR",
		},
	};
	const formatIntervalArray = (array) =>
		array.map((tech, index) => (index === 0 ? `${tech}` : ` - ${tech}`));

	const formatDateArray = (array) =>
		formatIntervalArray(
			array.map((date) => {
				const jsDate = DateTime.fromObject(date).toJSDate();
				return `${jsDate
					.toLocaleString(componentData.localeDateString[language], {
						month: "short",
					})
					.toLocaleLowerCase()}. ${jsDate.toLocaleString(
					componentData.localeDateString[language],
					{
						year: "numeric",
					}
				)}`;
			})
		);

	return (
		<Pill>
			<div className="data-container">
				<div className="flex-container">
					<div className="data">
						<div className="header">
							<h1>{data.title[language]}</h1>
							{data.productHunt && (
								<a
									href={data.productHunt.linkHref}
									target="_blank"
									className="product-hunt-button"
									rel="noreferrer"
								>
									<img
										src={data.productHunt.imageLink}
										alt="Product Hunt button"
										loading="lazy"
									/>
								</a>
							)}
						</div>
						<div className="paragraph-container">
							{data.description[language]
								.split("\n")
								.map((paragraph, index) => (
									<p
										className={
											index === 0
												? "description frist-paragraph"
												: "description"
										}
										key={index}
									>
										{paragraph}
									</p>
								))}
						</div>
						<ImagesPresentation
							images={data.images}
							defaultPlaceholderSrc={defaultPlaceholder}
						/>
						<div className="aditional-data">
							<p className="technologies">
								{formatIntervalArray(data.technologies)}
							</p>
							<p className="dates">{formatDateArray(data.dates)}</p>
						</div>
					</div>
				</div>
				<div className="buttons-container">
					<a href={data.link} target="_blank" rel="noreferrer">
						<button className="demo link">Visit it ↗</button>
					</a>
					{data.linkGithub ? (
						<a href={data.linkGithub} target="_blank" rel="noopener noreferrer">
							<button className="github link">Repository ↗</button>
						</a>
					) : null}
				</div>
			</div>
		</Pill>
	);
};

const Pill = styled.div`
	width: 66%;
	.data-container {
		width: 100%;
		min-height: 10vw;

		margin-top: 4vh;

		background-color: #101e31;

		transition: 0.4s ease-in-out;

		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.364);

		border-radius: 10px;

		color: #fff;

		.flex-container {
			display: flex;
			flex-direction: column;

			padding: 3vh 5% 0px 5%;

			.data {
				display: flex;
				flex-direction: column;

				width: 100%;

				font-size: 10pt;
				font-family: "Roboto", sans-serif;

				.header {
					display: flex;
					align-items: center;
					justify-content: space-between;

					h1 {
						font-size: 28pt;
						font-family: "Poppins", sans-serif;
					}

					.product-hunt-button {
						width: 18%;
						min-width: 200px;

						img {
							width: 100%;
						}
					}
				}

				.description {
					padding-right: 1vw;

					width: 100%;

					&.frist-paragraph {
						margin-top: 0.5vh;
					}

					font-size: 14pt;
				}

				.images-presentation {
					align-self: center;
					margin-top: 8px;
				}

				.aditional-data {
					padding-top: 2vh;

					.technologies {
						color: #e29b00;

						margin-bottom: 2vh;
					}

					.dates {
						color: #9e9e9e;

						margin-bottom: 1vh;

						font-size: 11pt;
					}
				}
			}
		}

		.buttons-container {
			width: 100%;
			height: 7vh;

			display: flex;
			align-items: center;

			a {
				height: 100%;

				flex-grow: 1;

				button {
					height: 100%;
					width: 100%;

					border: none;

					cursor: pointer;

					background: rgba(255, 255, 255, 0.05);
					backdrop-filter: blur(4px);
					-webkit-backdrop-filter: blur(4px);

					&.demo {
						border-right: 1px solid #00000032;
						border-bottom-left-radius: 10px;
					}

					&.github {
						border-left: 1px solid #00000032;
						border-bottom-right-radius: 10px;
					}
				}
			}
		}
	}

	@media (max-width: 930px) {
		width: 95vw;

		.button-container {
			opacity: 0%;

			position: absolute;

			left: -10%;
		}

		.data-container {
			.flex-container {
				flex-direction: column;

				.data {
					width: 100%;

					.header {
						flex-direction: column;
						align-items: flex-start;

						.product-hunt-button {
							margin: 1vh 0px;
						}
					}
				}
			}
		}
	}

	@media (max-width: 550px) {
		.data-container .carousel {
			width: 100%;
		}
	}
`;

export default ProjectPill;
