import React, { useContext } from "react";
import styled from "styled-components";
import Experience from "../../components/Experience/Experience";
import LanguageContext from "../../Context/languajeContext";

const Index = () => {
	const language = useContext(LanguageContext).language;

	const data = {
		english: {
			aboutTitle: "About",
			about: [
				"I'm a Full Stack Developer based in Argentina passionate for the problems and his solutions.",
				"When I'm not working in solve problems, I'm playing the guitar 🎸",
			],
			technologiesTitle: "Technologies",
			projectsTitle: "Projects",
			contactTitle: "Contact",
		},
		spanish: {
			aboutTitle: "Sobre mí",
			about: [
				"Soy un desarrollador Full Stack de Argentina apasionado por los problemas y por sus soluciones.",
				"Cuando no estoy solucionando problemas, seguramente este tocando la guitarra 🎸",
			],
			technologiesTitle: "Tecnologías",
			projectsTitle: "Proyectos",
			contactTitle: "Contacto",
		},
	};

	const technologies = [
		{
			name: "Front End",
			items: [
				{
					name: "JavaScript",
					dominantColor: "#F3E31B",
				},
				{
					name: "React.js",
					dominantColor: "#04DCFC",
				},
				{
					name: "styled-components",
					dominantColor: "#D481B0",
				},
				{
					name: "SCSS",
					dominantColor: "#CC639B",
				},
			],
		},
		{
			name: "Back End",
			items: [
				{
					name: "TypeScript",
					dominantColor: "#2C7BC4",
				},
				{
					name: "Express",
					dominantColor: "#838383",
				},
				{
					name: "GraphQL",
					dominantColor: "#E434AC",
				},
				{
					name: "Jest",
					dominantColor: "#9C444C",
				},
			],
		},
		{
			name: "Database",
			items: [
				{
					name: "MySQL",
					dominantColor: "#0685bc",
				},
				{
					name: "MongoDB",
					dominantColor: "#15a052",
				},
				{
					name: "Firebase",
					dominantColor: "#ffcb30fe",
				},
			],
		},
		{
			name: "Tools",
			items: [
				{
					name: "WordPress",
					dominantColor: "#858585",
				},
				{
					name: "Linux",
					dominantColor: "#eab30e",
				},
				{
					name: "Digital Ocean",
					dominantColor: "#0484FC",
				},
				{
					name: "Docker",
					dominantColor: "#2894EC",
				},
			],
		},
	];

	const contact = [
		{
			title: "Linkedin",
			imageRoute: "https://i.ibb.co/MSBQdVV/Linkedin.png",
			link: "https://www.linkedin.com/in/francisco-pessano",
			nameOnThis: "Francisco Pessano",
		},
		{
			title: "GitHub",
			imageRoute: "https://i.ibb.co/rsFXybP/g-i-t-h-u-b.png",
			link: "https://github.com/FranP-code",
			nameOnThis: "FranP-code",
		},
	];

	const Styles = styled.main`
		width: 66%;

		padding-bottom: 15vh;
		margin: auto;
		margin-top: -1vh;

		h2,
		h3,
		h4,
		h5 {
			color: #d3d3d3;
			font-family: "Poppins";
		}

		section {
			display: flex;

			margin-top: 6vh;

			color: #a8a8a8;

			& > * {
				flex-grow: 1;
				width: min-content;
			}

			h2 {
				width: 65%;
				flex-grow: 0;
			}
		}

		section.about {
			.about-text {
				p {
					width: 100%;
					margin-bottom: 1vh;

					:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		section.technologies {
			.items {
				display: inline-flex;
				flex-wrap: wrap;
				row-gap: 0.5vh;

				.stack {
					width: 50%;

					h3 {
						width: 100%;
					}
				}
			}
		}

		section.contact {
			ul {
				li {
					width: 100%;
				}
			}
		}

		@media (max-width: 930px) {
			width: 95%;
		}
	`;

	const TechnologyStyle = styled.li`
		:hover {
			color: ${(props) => props.dominantColor};
		}
	`;

	return (
		<Styles>
			<Experience />
			<section className="about">
				<h2>{data[language].aboutTitle}</h2>
				<div className="about-text">
					{data[language].about.map((paragraph) => (
						<p>{paragraph}</p>
					))}
				</div>
			</section>
			<section className="technologies">
				<h2>{data[language].technologiesTitle}</h2>
				<div className="items">
					{technologies.map((tech) => (
						<div className="stack">
							<h3>{tech.name}</h3>
							<ul>
								{tech.items.map((item) => (
									<TechnologyStyle dominantColor={item.dominantColor}>
										{item.name}
									</TechnologyStyle>
								))}
							</ul>
						</div>
					))}
				</div>
			</section>
			<section className="contact">
				<h2>{data[language].contactTitle}</h2>
				<ul>
					{contact.map((item) => (
						<li>
							{item.link ? (
								<a href={item.link} target="_blank" rel="noreferrer">
									{item.title} ↗
								</a>
							) : (
								<p>{item.nameOnThis}</p>
							)}
						</li>
					))}
				</ul>
			</section>
		</Styles>
	);
};

export default Index;
