import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Navbar = () => {

    const Styles = styled.nav`
        width: 66%;

        display: flex;
        justify-content: space-around;

        padding: 1.5vh 7vw;
        margin: auto;
        margin-top: 2vh;
        margin-bottom: 2vh;

        background-color: #2d3e55;

        border-radius: 6px;

        box-shadow: 0px 1vh 40px #0000007d;

        a {
            color: #d8d8d8;
            text-decoration: none;
            text-shadow: 0px 0.1vh 10px #ffffffc0;

            &:hover {
                text-shadow: 0px 0.1vh 10px #ffffff;
            }

            &:active {
                text-shadow: 0px 0.1vh 10px #ffffff8f;
            }
        }

        @media (max-width: 930px){

            width: 95vw;
        }
    `

    const paths = [
        {path: "https://github.com/FranP-code", external: true, name: 'github'},
        {path: "", external: false, name: ""}, // this is root.
        {path: "projects", external: false, name: "projects"}]

    return (
        <Styles>
            {
                paths.map(path => {
                    const pathName = path.name.length === 0 ? "/" : `/${path.name}`
                    if (path.external) {
                        return (
                            <Link to={{pathname: path.path}} target="_blank">
                                {pathName}
                            </Link>
                        )
                    }
                    return (
                        <Link
                            to={`/${path.path}`}
                        >
                            {pathName}
                        </Link>
                    )
                })
            }
        </Styles>
    )
}

export default Navbar