import React, { useContext } from "react";
import { DateTime } from "luxon";
import getDate from "../../scripts/getDate";
import styled from "styled-components";
import LanguageContext from "../../Context/languajeContext";

const ExperienceList = ({ data }) => {
	const { language } = useContext(LanguageContext);

	const componentData = {
		aptitudes: {
			english: "Aptitudes",
			spanish: "Aptitudes",
		},
		locale: {
			english: "en",
			spanish: "es",
		},
		monthsWord: {
			english: "months",
			spanish: "meses",
		},
		actuallyWord: {
			english: "actually",
			spanish: "actualidad",
		},
	};
	const getDiffDate = (date1, date2) => {
		return DateTime.fromISO(date2 || DateTime.now().toISO())
			.setLocale(componentData.locale[language])
			.diff(DateTime.fromISO(date1), "months")
			.toObject();
	};
	return (
		<>
			{data
				.sort((a) => !a.end)
				.map(
					({
						appointment,
						aptitudes,
						companyImageUrl,
						companyTitle,
						description,
						end,
						start,
					}) => {
						return (
							<ExperiencePill>
								<ExperienceImage
									alt={`${companyTitle} icon`}
									src={companyImageUrl}
									primary={!end}
								/>
								<ExperienceData>
									<h3>{companyTitle}</h3>
									<h4>{appointment}</h4>
									<p>
										{getDate(start, language)} -{" "}
										{end
											? getDate(end, language)
											: componentData.actuallyWord[language]}{" "}
										· {Math.ceil(getDiffDate(start, end).months)}{" "}
										{componentData.monthsWord[language]}
									</p>
									<JobDescription>{description[language]}</JobDescription>
									<JobAptitudes>
										<span>{componentData.aptitudes[language]}: </span>
										<JobAptitudesList>
											{aptitudes.map((aptitud) => (
												<li>{aptitud}</li>
											))}
										</JobAptitudesList>
									</JobAptitudes>
								</ExperienceData>
							</ExperiencePill>
						);
					}
				)}
		</>
	);
};

const ExperienceData = styled.div``;

const ExperiencePill = styled.div`
	color: ${({ theme }) => theme.colors.textColors.main};
	display: flex;
	width: 100% !important;
`;

const ExperienceImage = styled.img`
	border-radius: 20px;
	height: auto;
	margin-right: ${({ primary }) => (primary ? "10px" : "35px")};
	max-height: ${({ primary }) => (primary ? "100px" : "75px")};
	max-width: ${({ primary }) => (primary ? "100px" : "75px")};
	object-fit: contain;
`;

const JobDescription = styled.div`
	margin-top: 6px;
`;

const JobAptitudes = styled(JobDescription)`
	display: flex;
	& > span {
		font-weight: 600;
	}
`;

const JobAptitudesList = styled.ul`
	display: flex;
	column-gap: 4px;
	margin-left: 4px;
`;

export default ExperienceList;
