import React from 'react'
import styled from 'styled-components'

const Credits = () => {

    const Div = styled.div`
			${"" /* font-family: "Be Vietnam Pro", sans-serif; */}

			width: 66%;
			height: 66vh;

			margin: auto;

			.header-container {
				display: flex;
				justify-content: center;

				margin-bottom: 2vh;

				user-select: none;

				header {
					width: 75vw;

					padding: 2vh 0px;

					text-align: center;

					color: #fff;
					border-bottom: 1px solid #ffffff57;
				}
			}

			a {
				color: (({theme}) => theme.colors.linkColor);
			}

			ul {
				list-style: none;

				li:before {
					content: "• ";
					color: #fff;
				}

				li {
					font-size: 1.2em;
				}
			}

			@media (max-width: 930px) {
				width: 95vw;
			}
		`;

    const data = [
        {
            "name": "Page icon",
            "link": "https://www.flaticon.com/free-icon/porfolio_1156995"
        },
        {
            "name": "Color Pallete",
            "link": "https://colorhunt.co/palette/2d4059ea5455f07b3fffd460"
        },

        {
            "name": "Font titles - Be Vietnam Pro",
            "link": "https://fonts.google.com/specimen/Be+Vietnam+Pro?preview.size=100"
        },

        {
            "name": "Font body - Roboto",
            "link": "https://fonts.google.com/specimen/Roboto"
        },

        {
            "name": "Carousel component",
            "link": "https://www.npmjs.com/package/react-responsive-carousel"
        },

        {
            "name": "Icons",
            "link": "https://tablericons.com"
        },

        {
            "name": "Go to top button animation",
            "link": "https://animate.style"
        },

        {
            "name": "Typing effect",
            "link": "https://www.npmjs.com/package/react-typing-effect"
        },

        {
            "name": "Loading animation",
            "link": "https://uiball.com/loaders"
        }
    ]

    return (
        <Div>
            <div className="header-container">
                <header>
                    <h1>Credits</h1>
                </header>
            </div>
            <ul>
                {
                    data.map(object => (

                        <li><a href={object.link} target="_blank" rel="noreferrer">{object.name}</a></li>
                    ))
                }
            </ul>
        </Div>
    )
}

export default Credits
