import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components';
import './blur.css';

const animationTime = 0.15;

const ImagesPresentation = ({images, defaultPlaceholderSrc}) => {
    const [page, setPagination] = useState(0)
    const [className, setClassName] = useState('')
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        function handleResize() {
            const userWidth = window.innerWidth
            const operation = userWidth >= 930 ? (userWidth / 2 - userWidth / 12) : userWidth / 7
            const calculatedWidth = userWidth - operation
            setWidth(calculatedWidth);
            setHeight(calculatedWidth * (9 / 17))
        }
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const setPage = (pagination) => {
        if (pagination < 0 || images.length <= 1) {
            return
        }
        setClassName('fade-out')
        setTimeout(() => {
            setClassName('fade-in')
            if (pagination >= 0 && pagination < images.length) {
                setPagination(pagination)
                return
            }
            setPagination(0)
        }, animationTime * 1000)
    }

    return (
        <ImagesPresentationContainer className='images-presentation' width={width}>
            <LazyLoadImageContainer className={className}>
                {
                    images.map((image, index) => (
                        page === index &&
                            <LazyLoadImage
                                afterLoad={() => {
                                    const img = new Image();
                                    img.src = images[index + 1]?.src
                                }}
                                effect="blur"
                                height={height}
                                placeholderSrc={(image.placeholder || defaultPlaceholderSrc)}
                                src={image.src}
                                style={{borderRadius: '4px'}}
                                width={width}
                            />
                    ))
                }
            </LazyLoadImageContainer>
            <ButtonsContainer>
                <Button onClick={() => setPage(page - 1)}>Previous</Button>
                <Button onClick={() => setPage(page + 1)}>Next</Button>
            </ButtonsContainer>
            <PaginationPoints>
                {images.map((image, index) => {
                    return <span style={{
                        fontSize: index === page ? "30px" : "15px"
                    }}>•</span>
                })}
            </PaginationPoints>
        </ImagesPresentationContainer>
    )
}

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 3px;
    height: 100%;
    width: 100%;
`

const Button = styled.button`
    width: 100%;
    height: 25px;
    border-radius: 6px;
    color: #bbb;
    background: rgba(78, 100, 100, 0.25);
    backdrop-filter: blur(14px);
`

const ImagesPresentationContainer = styled.div`
    width: ${({width}) => width}px;
    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes fade-out {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
        }
    @keyframes fade-out {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .fade-in {
	    -webkit-animation: fade-in ${animationTime}s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	    animation: fade-in ${animationTime}s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
    .fade-out {
	    -webkit-animation: fade-out ${animationTime}s ease-out both;
	    animation: fade-out ${animationTime}s ease-out both;
    }
`

const LazyLoadImageContainer = styled.div``

const PaginationPoints = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export default ImagesPresentation