import { DateTime } from "luxon";

const getDate = (date, language) => {
	const functionData = {
		locale: {
			english: "en",
			spanish: "es",
		},
	};
	return DateTime.fromISO(date)
		.setLocale(functionData.locale[language])
		.toFormat("yyyy MMMM");
};

export default getDate;
