import React, { useContext } from "react";
import styled from "styled-components";
import LanguageContext from "../../Context/languajeContext";
import BreakingLine from "../BreakingLine/BreakingLine";
import ExperienceList from "./ExperienceList";
import kimcheImage from "./images/kimche.png";
import JobsList from "./JobsList";

const Experience = () => {
	const { language } = useContext(LanguageContext);

	const componentData = {
		title: {
			english: "Experience",
			spanish: "Experiencia",
		},
	};
	const experienceData = [
		{
			companyImageUrl: kimcheImage,
			companyTitle: "Kimche",
			appointment: "Software Developer",
			description: {
				english:
					"Development of functionalities of the company's products, both at the frontend level and backend.",
				spanish:
					"Desarrollo de funcionalidades de los productos de la empresa, tanto a nivel de frontend como de backend.",
			},
			start: "2022-06",
			end: undefined,
			aptitudes: ["React.js", "Node.js", "GraphQL", "Docker"],
		},
	];
	const freelancingJobsData = [];
	return (
		<ExperienceContainer>
			<h2>{componentData.title[language]}</h2>
			<ExperienceList data={experienceData} language={language} />
			{!!freelancingJobsData.length && (
				<BreakingLine color="#aaa" height="1px" />
			)}
			<JobsList data={freelancingJobsData} />
		</ExperienceContainer>
	);
};

const ExperienceContainer = styled.section`
	row-gap: 16px;
	display: flex;
	flex-direction: column;
`;

export default Experience;
