import React, { useState, useEffect } from 'react'

import topArrows from './top-arrows.svg'

const BackToTopButton = ({topElementId, Button}) => {
   
    const [buttonVisible, setButtonVisible] = useState('dont inizializated')

    useEffect(() => {

        const toggleVisibilty = () => {

            if (window.pageYOffset > 700) {
                setButtonVisible(true)

            } else {
                setButtonVisible(false)
            }
        }
        
        window.addEventListener("scroll", toggleVisibilty)

    }, [])

    return (
        <a href={`#${topElementId}`}>
            <Button
                className={buttonVisible === true ? 'animate__animated animate__fadeIn' : "hidden"}
                disabled={!buttonVisible ? true : false}
            >
                <img src={topArrows} alt="top-arrows" />
            </Button>
        </a>
        
    )
}

export default BackToTopButton
