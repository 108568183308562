import React from "react";
import styled from "styled-components";

const BreakingLine = ({ color, height }) => {
	return (
		<BreakingLineContainer>
			<BreakingLineStyles color={color} height={height} />
		</BreakingLineContainer>
	);
};

const BreakingLineContainer = styled.div`
	height: 100% !important;
	padding: 10px 0px;
	width: 100% !important;
`;

const BreakingLineStyles = styled.div`
	background: ${({ color }) => color || "#fff"};
	border-radius: 9px;
	height: ${({ height }) => height || "10px"}; !important;
	width: 100% !important;
`;

export default BreakingLine;
