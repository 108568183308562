import React, { useContext } from 'react'

import styled from 'styled-components'
import LanguageContext from '../../Context/languajeContext'

const LanguageSelector = () => {

    const Div = styled.div`

        font-weight: bolder;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        margin-right: 1vw; 

        user-select: none;

        span {

            color: #fff;
            text-decoration: none;
            cursor: pointer;

            &.disabled {

                color: #b6b6b6;
                cursor: default;
            }
        }

        span:nth-child(2){

            margin-top: 0.5vh;
        }

        @media (max-width: 900px){

            left: 90%;
        }

        @media (max-width: 930px){
            
            box-sizing: border-box;
            /* width: 95vw; */

            position: static;

            flex-direction: initial;
            
            margin-top: 2vh;
            margin-right: 0;
            margin-bottom: 2vh;

            span {

                height: 7.5vh;
                
                flex-grow: 1;

                display: flex;
                justify-content: center;
                align-items: center;

                background-color: #ffffff16;

                &.disabled {
                    background-color: #ffffff08;
                }
            }

            span:nth-child(2){
                margin: 0;
            }
            
        }
    `
    const context = useContext(LanguageContext) 

    return (
        <Div>
            <span
                onClick={() => context.setLanguage('english')}
                className={context.language === 'english' ? 'disabled' : null}
            >
                EN
            </span>
            
            <span
                onClick={() => context.setLanguage('spanish')}
                className={context.language === 'spanish' ? 'disabled' : null}
            >
                ES
            </span>
        </Div>
    )
}

export default LanguageSelector
