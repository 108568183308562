/* eslint-disable import/no-anonymous-default-export */
const colors = {
	pageBackgroundColor: "#111822",
	secondaryBackgroundColor: "",
	linkColor: "#2285ff",
	componentBackgroundColors: {
		first: "#192330",
	},
	textColors: {
		main: "#fff",
	},
};

export default {
	colors,
};
