function determinateUserLanguage() {

    const userLang = navigator.language || navigator.userLanguage;

    if (userLang.includes('es')) {
        return 'spanish'
    }
    return 'english'
}

export default determinateUserLanguage