import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";

import "./App.css";
import determinateUserLanguage from "./scripts/determinateUserLanguage";

import LanguageContext from "./Context/languajeContext";

import Footer from "./components/Footer/Footer";

import Credits from "./Pages/Credits/Credits";
import Projects from "./Pages/Porfolio/Projects/Projects";
import Blog from "./Pages/Blog/Blog";
import Index from "./Pages/Index/Index";

import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import { useEffect } from "react";
import theme from "./theme";
import { ThemeProvider } from "styled-components";

function App() {
	const [language, setLanguage] = useState(determinateUserLanguage());

	useEffect(() => {
		const root = document.querySelector(":root");
		root.style.setProperty(
			"--page-background-color",
			theme.colors.pageBackgroundColor
		);
		root.style.setProperty("--link-color", theme.colors.linkColor);
	}, []);

	return (
		<LanguageContext.Provider value={{ language, setLanguage }}>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Header />
					<Navbar />
					<Switch>
						<Route path="/credits">
							<Credits />
						</Route>
						<Route path="/projects">
							<Projects />
						</Route>
						<Route path="/blog">
							<Blog />
						</Route>
						<Route path="/">
							<Index />
						</Route>
					</Switch>
					<Footer />
				</BrowserRouter>
			</ThemeProvider>
		</LanguageContext.Provider>
	);
}

export default App;