import React, {useContext} from 'react'
import styled from 'styled-components'

import LanguageContext from '../../Context/languajeContext'

const Footer = () => {

    const text = {

        footerMessgae: {

            spanish: "Creado con 💓 por ",
            english: "Created with 💓 by "
        },

        credits: {
            spanish: "Creditos",
            english: "Credits"
        },
    }

    const language = useContext(LanguageContext).language

    const FooterContainer = styled.footer`
			display: flex;
			justify-content: center;

			footer {
				${"" /* font-family: "Be Vietnam Pro", sans-serif; */}

				width: 66%;

				display: flex;
				justify-content: space-between;

				padding: 4vh 2vw;
				margin: 3vh 0px;

				background-color: (({theme}) => theme.componentBackgroundColors.first);

				border-radius: 10px;

				color: #a8a8a8;

				box-shadow: 0px 0px 40px #0000007d;

				p {
					display: flex;
					align-items: center;

					a {
						margin-left: 3px;
					}
				}
			}

			@media (max-width: 930px) {
				footer {
					width: 95vw;

					ul {
						list-style: none;

						margin-top: 1vh;
					}
				}
			}

			@media (max-width: 550px) {
				footer {
					flex-direction: column;
				}
			}
		`;

    return (
        <FooterContainer>
            <footer>
                <ul>
                    <li><p>{text.footerMessgae[language]}<a href='#body'>Francisco Pessano</a></p></li>
                    <li><a href='./credits' target="_blank">{text.credits[language]}</a></li>
                </ul>
                <ul>
                </ul>
            </footer>
        </FooterContainer>
    )
}

export default Footer
