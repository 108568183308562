import React, { useContext } from 'react'
import styled from 'styled-components'

import LanguageContext from '../../../Context/languajeContext'

import BackToTopButton from '../../../components/BackToTopButton/BackToTopButton'
import ProjectPill from '../../../components/ProjectPill/ProjectPill'

import data from './ProjectsData.json'
import { DateTime } from "luxon";

const Projects = () => {
    
    const Div = styled.div`
        
        height: 100%;
		width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-bottom: 2vh;
    `

    const Button = styled.button`

        position: fixed;

        top: 83.5vh;
        right: 0;

        width: 3vw;
        max-width: 77.44px;
        height: 3vw;
        max-height: 77.44px;

        box-sizing: content-box;

        margin-top: 2vh;
        margin-right: 2.3vw;

        border-radius: 15%;
        border: none;
        border: 5px solid #00000040;

        background-color: #c4c4c4;

        animation-duration: 0.3s;

        img {
            user-drag: none;
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }

        &.hidden {

            opacity: 0;
            cursor: default;

            transition: 0.3s ease all;
        }


        &:active {

            transform: translate(2px, 2px)
        }

        img {

            width: 2vw;
            height: 2vw;
            /* cursor: pointer; */
            /* fill: #4e4e4e  */
        }

        @media (max-width: 930px){
            
            position: absolute;
            left: -70%;
        }
    `
    
    const language = useContext(LanguageContext).language
    
    return (
			<Div>
				{data.projectsData
					.sort(
						(a, b) =>
							DateTime.fromObject(b.dates[0]).toJSDate().getTime() -
							DateTime.fromObject(a.dates[0]).toJSDate().getTime()
					)
					.map(
						(project, index) =>
							!project.hide && (
								<ProjectPill data={project} key={index} language={language} />
							)
					)}
				<BackToTopButton topElementId="body" Button={Button} />
			</Div>
		);
}

export default Projects
