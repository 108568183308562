import React, { useContext } from 'react'
import ReactTypingEffect from 'react-typing-effect'
import styled from 'styled-components'

import LanguageSelector from '../LanguageSelector/LanguageSelector'

import LanguageContext from '../../Context/languajeContext'

import face from './Face.jpg'

const Header = () => {

    const Styles = styled.header`
			width: 66%;

			position: relative;
			z-index: 1000;

			margin: auto;
			margin-top: 3vh;

			padding: 2vh 0px 5vh 0px;

			background-color: (({theme}) => theme.componentBackgroundColors.first);

			box-shadow: 0px 1vh 40px #0000007d;

			border-radius: 15px;

			${"" /* font-family: "Be Vietnam Pro", sans-serif; */}
			color: #fff;

			.img-container {
				display: flex;
				justify-content: center;

				img {
					border-radius: 100%;

					width: 10vw;
					min-width: 150px;
					height: 10vw;
					min-height: 150px;

					object-fit: cover;

					user-select: none;
				}
			}

			.titles {
				display: flex;

				flex-direction: column;
				align-items: center;

				*::selection {
					background-color: #ffe83d;
					color: #000000;
				}

				h1 {
					font-size: 2.5em;
				}

				h2 {
					font-size: 1.6em;
				}
			}

			.review {
				margin-top: 5vh;

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				text-align: center;
			}

			@media (max-width: 930px) {
				width: 95vw;
			}
		`;

    const language = useContext(LanguageContext).language

    const data = {
        english: {
            review: ["Passionate developer with about 2 years of experience.", "Allergic to the eternal student syndrome and eager for new challenges."]
        },
        spanish: {
            review: ["Apasionado desarrollador con alrededor de dos año de experiencia.", "Alérgico al síndrome del estudiante eterno y ansioso por los nuevos desafíos."]
        }
    }
  return (
		<Styles>
			<LanguageSelector />
			<div className="img-container">
				<img
					src={
						process.env.REACT_APP_ENV_MODE === "production"
							? face
							: "https://i.pinimg.com/originals/a1/08/5f/a1085f3864ba649f697a1ef2de2e91c9.jpg"
					}
					alt="face"
				/>
			</div>
			<div className="titles">
				<h1>Francisco Pessano</h1>
				<h2>Software Developer</h2>
			</div>
			<div className="review">
				<ReactTypingEffect
					text={data[language].review}
					speed={50}
					eraseSpeed={25}
					eraseDelay={1500}
				/>
			</div>
		</Styles>
	);
}

export default Header